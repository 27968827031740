import { Card, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import RightArrowWhite from "../assets/Icons/accordian_white.svg";
import RightArrow from "../assets/Icons/go.svg";
import Clm2Img from "../assets/Icons/mail.svg";
import Clm1Img from "../assets/Icons/phone.svg";
import BackArrowIcon from "../assets/Images/accordian.svg";
import scrollImage from "../assets/Images/DigitalTransformation/2_2x.png";
import BannerBgImage from "../assets/Images/Hire Remote team/hire_remote_teams.jpg";
import MiniCardBgImage7 from "../assets/Images/Homepage/code_ux_audit.png";
import MiniCardBgImage3 from "../assets/Images/Homepage/full_product_development.png";
import MiniCardBgImage9 from "../assets/Images/Homepage/machine_learning.png";
import MiniCardBgImage1 from "../assets/Images/Homepage/mobile_application_development.png";
import MiniCardBgImage4 from "../assets/Images/Homepage/MVP.png";
import MiniCardBgImage5 from "../assets/Images/Homepage/Product_designing_and_Prototyping.png";
import MiniCardBgImage6 from "../assets/Images/Homepage/product_scaling.png";
import MiniCardBgImage2 from "../assets/Images/Homepage/web_application_development.png";
import hoverCardImg5 from "../assets/Images/HowWeDoIt/Centralised_repository.svg";
import hoverCardImg2 from "../assets/Images/HowWeDoIt/Completely_Automated_SSDLC.svg";
import hoverCardImg3 from "../assets/Images/HowWeDoIt/Cost_cutting_on_Maintenance_Updates.svg";
import hoverCardImg4 from "../assets/Images/HowWeDoIt/Reduced_Implementation_Time.svg";
import hoverCardImg1 from "../assets/Images/HowWeDoIt/Synchronised_Development_Operations.svg";
import MiniImageBannerBgImg1 from "../assets/Images/HowWeDoIt/who_we_work_with.png";
import ogimage from "../assets/Images/Hire Remote team/hire_remote_team_OG@2x.png";
import clutchLogo from "../assets/Images/WhatWeDo/clutch-co-vector-logo.svg";
import Button from "../components/Button";
import Layout from "../components/layouts/layout";
import MiniCardWithBgImg from "../components/MiniCardWithBgImg";
import MobileSmoothSlider from "../components/MobileSmoothSlider";
//PlainCard
import PlainCard from "../components/SixPlainCard";
import DataService from "../services/api/data_services";
import { BASE_SITE_URL } from "../shared/constants";
//BlurCardList
import BlurCardList from "../templates/BlurCardList";
//Image Banner
import ImageBanner from "../templates/ImageBanner";
//Info View
import Infoview from "../templates/InfoView";
import Megaview from "../templates/MegaView";
//Mini Image Banner1
import MiniImageBanner1 from "../templates/MiniImageBanner1";
//Miniview
import Miniview from "../templates/MiniView";

//Medium View
import MediumviewWithHover from "../templates/MediumViewWithHover";
import MediumviewImage1 from "../assets/Images/Hire Remote team/deliver great employee.jpg";
import MediumviewImage2 from "../assets/Images/Hire Remote team/hiresomeone.jpg";
import MediumviewImage3 from "../assets/Images/Hire Remote team/stay complaint.jpg";

// Two Column Contact info
import TwoColumnContactInfo from "../templates/TwoColumnContactInfo";

//Icon Card List
import IconCardList from "../templates/IconCardList";
import IconCardListIcon1 from "../assets/Images/Hire Remote team/Group 6127.svg";
import IconCardListIcon2 from "../assets/Images/Hire Remote team/Affiliate.svg";
import IconCardListIcon3 from "../assets/Images/Hire Remote team/Registration.svg";
import IconCardListIcon4 from "../assets/Images/Hire Remote team/Teamwork.svg";
import IconCardListIcon5 from "../assets/Images/Hire Remote team/Group 6142.svg";
import IconCardListIcon6 from "../assets/Images/Hire Remote team/Valuations.svg";
import IconCardListIcon7 from "../assets/Images/Hire Remote team/Digital Agreement.svg";
import IconCardListIcon8 from "../assets/Images/Hire Remote team/Investor.svg";
import IconCardListIcon9 from "../assets/Images/Hire Remote team/Online Shoping.svg";

//Six Hover Cards
import SixPlainCards from "../templates/SixPlainCards";
import HoverCardWithBtnIcon1 from "../assets/Images/Hire Remote team/Group 5721.svg";
import HoverCardWithBtnIcon2 from "../assets/Images/Hire Remote team/Group 6140.svg";
import HoverCardWithBtnIcon3 from "../assets/Images/Hire Remote team/Group 5813.svg";
import HoverCardWithBtnIcon4 from "../assets/Images/Hire Remote team/Group 2827.svg";
import HoverCardWithBtnIcon5 from "../assets/Images/Hire Remote team/Group 3119.svg";

//HoverCardWithButton
import HoverCardWithButton from "../components/HoverCardWithButton";
import hoverCardImg11 from "../assets/Images/Hire Remote team/Scale your team for new and existing projects.svg";
import hoverCardImg12 from "../assets/Images/Hire Remote team/Transition an existing team.svg";
import hoverCardImg13 from "../assets/Images/Hire Remote team/Build new remote capabilities, from one person to whole team.svg";

// Mobile movie slider
import MobileMovieSlider from "../components/MobileMovieSlider";
import awardsForMobile from "../assets/Images/About Us/awards____2x.png";

import ourOffer from "../assets/Images/scrollImages/Group_6110_2x.png";

const HireRemoteTeam = () => {
  const [articleRawData, setArticleRawData] = useState([]);
  const [articleLimit, setArticleLimit] = useState(6);
  const [articlePage, setArticlePage] = useState(1);
  const [articleData, setArticleData] = useState([]);
  const [megaviewData, setMegaViewData] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const toggleHover = () => {
    setIsHover(!isHover);
  };

  useEffect(() => {
    DataService.getData(
      "/admin/api/all-services?limit=1&type=White Paper&service_type=Digital Transformation"
    ).then((response) => {
      setMegaViewData(response);
    });
  }, []);

  useEffect(() => {
    DataService.getData(
      `/admin/api/all-services?limit=${articleLimit}&page=${articlePage}&type=Article&service_type=Digital Transformation&component=mini_view`
    ).then((response) => {
      setArticleData([...articleData, ...response.data]);
      setArticleRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleLimit, articlePage]);

  /**
   * Image Banner
   */
  const ImageBannerData = {
    bnrImg: BannerBgImage,
    component: "Who",
  };

  /**
   * Infoview
   */
  const InfoviewData = {
    InfoviewTitle: (
      <div className="mbl-br-hide ttc">
        Build Your Remote Teams, <br />
        <span className="red-line3letter">The</span> Easy Way
      </div>
    ),
    InfoviewContent: (
      <>
        We provide a full range of services to assist you in hiring, managing
        and implementing all the relevant human resource functions for you to
        start building and scaling a reliable and dynamic remote team. There has
        never been a better time to embrace remote working by building an agile
        team with Digiryte that’s located right where you need it…. at your
        fingertips. With the new way of working to the new standard due to
        technological advancements and a shift in mindset, there’s never a
        better time to make the switch and make the most of its tremendous
        advantages.
      </>
    ),
  };

  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  const PlainCardData = {
    cardsData: [
      {
        HoverCardWithBtntitle: "Microservices based",
        HoverCardWithBtnsubtitle: "(Improves Agility)",
        HoverCardWithBtnIcon: hoverCardImg1,
        HoverCardWithBtnIconAlt: "Microservices based",
        HoverCardIconClass: "plain-card-button-main-img",
      },
      {
        HoverCardWithBtntitle: "API-first",
        HoverCardWithBtnIcon: hoverCardImg2,
        HoverCardWithBtnIconAlt: "API-first",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle: "(Secure Software Development Life-cyle)",
      },
      {
        HoverCardWithBtntitle: "Cloud-native SaaS",
        HoverCardWithBtnIcon: hoverCardImg3,
        HoverCardWithBtnIconAlt: "Cloud-native SaaS",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle: "(Cost cutting without Compromising Quality)",
      },
      {
        HoverCardWithBtntitle: "Headless",
        HoverCardWithBtnIcon: hoverCardImg4,
        HoverCardWithBtnIconAlt: "Headless",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle: "(Months to Minutes)",
      },
      {
        HoverCardWithBtntitle: "ISO 27001 Compliant",
        HoverCardWithBtnIcon: hoverCardImg5,
        HoverCardWithBtnIconAlt: "ISO 27001 Compliant",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle: "(Secure Software Development Lifecyle)",
      },
    ],
    lastCardData: {
      title: "Let's Talk",
      subTitle: "We'd love to help with your product.",
      variant: "contained",
      label: "GET IN TOUCH",
      customClass: "miniCrdBgImg-main-border",
      route: "/contact-us",
    },
  };

  /**
   * Hover card button
   */

  const hoverCardButtonData = [
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "Scale your team for new and existing projects",
      HoverCardWithBtnIcon: hoverCardImg11,
      HoverCardWithBtnIconAlt: "Delivery",
      HoverCardIconClass: "mb-10",
    },
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "Transition an existing team",
      HoverCardWithBtnIcon: hoverCardImg12,
      HoverCardWithBtnIconAlt: "Processes",
      HoverCardIconClass: "mb-10",
    },
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle:
        "Build new remote capabilities, from one person to whole team",
      HoverCardWithBtnIcon: hoverCardImg13,
      HoverCardWithBtnIconAlt: "Output",
      HoverCardIconClass: "mb-10",
    },
  ];
  /**
   * Mini Image Banner 1
   */

  const MiniImageBanner1Data = {
    title: (
      <>
        Who we work <br /> with
      </>
    ),
    titleClass: "mini-image-banner-title-area3 title-header2",
    image: MiniImageBannerBgImg1,
    altTxt: "Who we work with",
    btnClassName: "image-banner-resize-btn",
    btnLabel: "TAKE A LOOK",
    btnVariant: "contained",
    btnColor: "primary",
    route: "/who-we-work-with",
    underline: true,
    tooltip: "yes",
    tip: "TAKE A LOOK",
    underlineClass: "black-line",
  };
  /**
   * Blur Card List Data
   */

  const BlurCardListData = {
    cardsData: [
      {
        image: MiniCardBgImage1,
        title: "Mobile Application Development",
        subTitle:
          "Take business to your customers by building advanced native and hybrid mobile apps for both Android and iOS Platforms.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mobile-application-development",
      },
      {
        image: MiniCardBgImage2,
        title: "Web Application Development",
        subTitle:
          "Accelerate different functions of your business by creating custom web applications using the latest in digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/web-application-development",
      },
      {
        image: MiniCardBgImage3,
        title: "Full Product Development",
        subTitle:
          "The process of full product development encompasses all steps needed to take your product from concept to market place.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/full-product-development",
      },
      {
        image: MiniCardBgImage4,
        title: "MVP Development",
        subTitle:
          "Build a basic model to demonstrate the core functionality of your product, test it with early adopters and fulfil the primary goal of your business.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mvp-development",
      },
      {
        image: MiniCardBgImage5,
        title: "Product Designing and Prototyping",
        subTitle:
          "Create a prototype of your product to visualise your idea and generate feedback without incurring the building costs of a fully functioning system.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-designing-and-prototyping",
      },
      {
        image: MiniCardBgImage6,
        title: "Product Scaling",
        subTitle:
          "Found your product-market fit? Great! Let us help you with a profitable scaling process that provides both sustainability and stability for your product.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-scaling",
      },
      {
        image: MiniCardBgImage7,
        title: "Code & UX Audit",
        subTitle:
          "With a regular audit, we can help you fix your product, improve its performance, gain new users, and boost your brand reputation in the marketplace.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/code-ux-audit",
      },
      {
        image: MiniCardBgImage9,
        title: "Machine Learning",
        subTitle:
          "Through machine learning, we can help you make informed decisions to improve business scalability and operational efficiency.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/machine-learning",
      },
    ],
    lastCardData: {
      title: "Let's Talk",
      subTitle: "We'd love to help with your product.",
      variant: "contained",
      label: "GET IN TOUCH",
      customClass: "miniCrdBgImg-main-border",
      route: "/contact-us",
    },
  };

  const quotedMiniCardData = {
    title: "Mobile App Dev for Automative Sales App",
    heading:
      "We can’t speak highly enough of Digiryte’s team because they bent over backward to help us launch our product.",
    content: "Founder, Bumper UK",
    footerContent: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>Authentic client review from</div>
        <div style={{ paddingLeft: "5px" }}>
          <img src={clutchLogo} alt="Clutch" height="50px" />
        </div>
      </div>
    ),
  };

  const miniviewDataArticle = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    tooltip: "yes",
    tip: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };

  //Movie Slider
  const movieSlider = {
    imageUrl: scrollImage,
  };

  /**
   * Six Hover Card
   */

  const SixHoverCardData = {
    HoverCardWithBtnvariant: "contained",
    HoverCardWithBtncolor: "primary",
    HoverCardWithBtnlabel: "LET'S TALK",
    HoverCardWithBtntitle: "Let’s Build Your MVP",
    HoverCardWithBtntitle1: "Business Diversification",
    HoverCardWithBtntitle2: "Access to Top Talent",
    HoverCardWithBtntitle3: "Diversity is Strength",
    HoverCardWithBtntitle4: "Lower Stress, Higher Retention",
    HoverCardWithBtntitle5: "Global Perspectives",
    HoverCardWithBtnIcon1: HoverCardWithBtnIcon1,
    HoverCardWithBtnIcon2: HoverCardWithBtnIcon2,
    HoverCardWithBtnIcon3: HoverCardWithBtnIcon3,
    HoverCardWithBtnIcon4: HoverCardWithBtnIcon4,
    HoverCardWithBtnIcon5: HoverCardWithBtnIcon5,
    HoverCardWithBtnContentClass: "btncontent mt-10",
    HoverCardWithBtnContent1:
      "Remote global teams enable businesses to build a strong foundations that can open up new opportunities by diversifying their operations around the world. As a result, they are well-positioned for success in a quickly changing world.",
    HoverCardWithBtnContent2:
      "The biggest benefit of remote teams is the ability to hire the best candidates for the business’s open roles, no matter where they live. This also allows you to build a team of top performers from around the globe .",
    HoverCardWithBtnContent3:
      "Remote workers on diverse teams are known to be happier, more engaged and more productive. According to recent studies, employees at diverse companies were three times more likely to be happy and were less likely to report wishing to leave their jobs within the next year.",
    HoverCardWithBtnContent4:
      "Remote workers feel less stressed. With average turnover replacement costs 1.5 to 2 times the employee’s salary, hiring remote first or including a remote work option is an effective way to save costs and reduce attrition rate.",
    HoverCardWithBtnContent5:
      "Having global team members is crucial for any company that hopes to successfully scale at an international level. With a diverse set of views and insights a remote team can be invaluable and bring a more holistic perspective to the table.",
    HoverCardWithBtnIconAlt1: "E-Commerce Website Development ",
    HoverCardWithBtnIconAlt2: "SAAS Product Development ",
    HoverCardWithBtnIconAlt3: "Platform Based Product Development ",
    HoverCardWithBtnIconAlt4: "CRM Development ",
    HoverCardWithBtnIconAlt5: "LMS Development ",
    whiteCard: "yes",
    lastCardData: {
      title: "Let's Talk",
      subTitle: "We'd love to help with your product.",
      variant: "contained",
      label: "GET IN TOUCH",
      customClass: "miniCrdBgImg-main-border",
      route: "/contact-us",
      tip: "GET IN TOUCH",
      tooltip: "yes",
    },
  };

  /**
   * Icon Card List Data
   */

  const IconCardListData = [
    {
      icon: IconCardListIcon4,
      altTxt:
        "Remote employees with English fluency, averaging over 160 hours of work a month",
      heading: (
        <>
          Remote Employees with
          <br /> English Fluency,
          <br /> Averaging Over 160
          <br /> Hours of Work a Month
        </>
      ),
    },
    {
      icon: IconCardListIcon9,
      altTxt:
        "Ability to connect with your team in real time, during normal business hours",
      heading: (
        <>
          Ability To Connect With
          <br /> Your Team In Real
          <br /> Time, During Your Normal <br />
          Business Hours
        </>
      ),
    },
    {
      icon: IconCardListIcon1,
      altTxt: "Guaranteed substantial savings on payroll",
      heading: (
        <>
          Guaranteed <br />
          Substantial savings <br /> on Payroll
        </>
      ),
    },
    {
      icon: IconCardListIcon2,
      altTxt: "Reduction of employee turnover rate",
      heading: "Reduction of Employee Turnover Rate",
    },
    {
      icon: IconCardListIcon3,
      altTxt: "Reduced time spent on HR functions as we handle all",
      heading: (
        <>
          Reduced Time Spent On <br /> HR Functions as we <br />
          Handle All
        </>
      ),
    },
    {
      icon: IconCardListIcon5,
      altTxt: "Minimise overheads by upto 60%",
      heading: (
        <>
          Minimise Overheads by
          <br /> Upto 60%
        </>
      ),
    },
    {
      icon: IconCardListIcon6,
      altTxt: "Increased productivity and profitability",
      heading: (
        <>
          Increased <br />
          Productivity and <br />
          Profitability
        </>
      ),
    },
    {
      icon: IconCardListIcon7,
      altTxt:
        "Complete transparency - No hidden fees, just one monthly payment",
      heading: (
        <>
          Complete Transparency -<br /> No Hidden Fees, Just
          <br /> One Monthly Payment
        </>
      ),
    },
    {
      icon: IconCardListIcon8,
      altTxt: "Highly skilled, motivated and productive employees",
      heading: (
        <>
          Highly Skilled, Motivated
          <br /> and Productive
          <br /> Employees
        </>
      ),
    },
  ];
  // Mobile movie slider
  const mobMovieSlider = {
    imageUrl: awardsForMobile,
  };
  const mobMovieSlider1 = {
    imageUrl: ourOffer,
  };

  /**
   * Medium View
   */

  const MediumviewData = {
    title: (
      <div className="ttc">
        Engage with us if you <br />
        <span className="red-line3letter red-3letter-mbl">Wan</span>t to
      </div>
    ),
    underline: true,
    individualBoxData: {
      IndividualBoxTitle: "Explore synergies",
      IndividualBoxBtnLabel: "GET IN TOUCH",
      IndividualBoxBtnVariant: "contained",
      IndividualBoxBtnColor: "primary",
      route: "/contact-us",
    },
    data: [
      {
        MediumviewImage: MediumviewImage2,
        MediumviewAlt: "On-Shore Model",
        MediumviewHeading:
          "Hire Someone Internationally Without Setting Up a Legal Entity",
        MediumviewContent: (
          <>
            The budget and resources required to set up an entity do not have to
            deter your business from hiring a remote, global team. Digiryte
            takes on the burden of legal, tax and financial setup, allowing you
            to quickly secure talent around the world, and do it fast without
            the delay of setting up new companies.
          </>
        ),
        MediumviewButtonColor: "primary",
        MediumviewButtonLabel: "Find Out More",
        route: "/contact-us",
      },
      {
        MediumviewImage: MediumviewImage3,
        MediumviewAlt: "Off-Shore Model",
        MediumviewHeading:
          "Stay Compliant With Local Laws and Protect Interest of Your Business",
        MediumviewContent: (
          <>
            Laws and regulations change fast. It’s a full time job just keeping
            up with them in your main country of business. As you add employees
            living in other countries to the list, the resources required to
            stay up to date and compliant will multiply. Let Digiryte handle
            this on an ongoing basis so you can focus on growing your business
            instead of staying compliant.
          </>
        ),
        MediumviewButtonColor: "primary",
        MediumviewButtonLabel: "Find Out More",
        route: "/contact-us",
      },
      {
        MediumviewImage: MediumviewImage1,
        MediumviewAlt: "Dual-Shore Model",
        MediumviewHeading:
          "Deliver Great Employee Experience and Build Trust With Your New Hires",
        MediumviewContent: (
          <>
            Part of getting the most out of your employees and ensuring they
            love their new role is building a great experience for them, from
            beginning to end. Digiryte frees up your internal team to focus on
            building the relationship with your new team member and assures rest
            is taken care of.
          </>
        ),
        MediumviewButtonColor: "primary",
        MediumviewButtonLabel: "Find Out More",
        route: "/contact-us",
      },
    ],
  };
  return (
    <Layout
      seo={{
        title: "Hire Remote Teams",
        ogtitle: "Hire Remote Teams | Digiryte",
        description:
          "We assist in hiring, managing and implementing all the relevant human resource functions for you to start building & scaling a reliable and dynamic remote teams",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}/hire-remote-teams`,
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-70">
        <Infoview InfoviewData={InfoviewData} />
      </div>
      <div className="mt-70 body-container">
        <h3 className="title-header ttc">
          What's So Great About Remote
          <br />
          <span className="red-line3letter">Tea</span>ms?
        </h3>
      </div>
      <div className="mt-50"></div>
      <SixPlainCards SixHoverCardData={SixHoverCardData} />
      <div className="mt-70 body-container ttc">
        <span className="title-header mbl-br-hide">
          With Digiryte’s Remote Team <br />{" "}
          <span className="red-line3letter">Sol</span>ution you can
        </span>
      </div>
      <div className="body-container mt-50 hide-in-mobile">
        <Grid container direction="row" spacing={3}>
          {hoverCardButtonData.map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={4}>
              <Card className="appsgridshadow">
                <HoverCardWithButton
                  componentName="product-design"
                  pageName="hire-remote"
                  HoverCardWithBtnCustomClass={item.HoverCardWithBtnCustomClass}
                  HoverCardWithBtntitle={item.HoverCardWithBtntitle}
                  HoverCardWithBtnIcon={item.HoverCardWithBtnIcon}
                  HoverCardWithBtnIconAlt={item.HoverCardWithBtnIconAlt}
                  HoverCardIconClass={item.HoverCardIconClass}
                  HoverCardWithBtnsubtitle={item.HoverCardWithBtnsubtitle}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
      <Grid className="show-only-in-mobile">
        <MobileSmoothSlider movieSlider={movieSlider} />
      </Grid>
      <div className="body-container mt-70">
        <h5 className="title-header ttc">
          <span className="red-line3letter">We </span> Offer
        </h5>
        <div className="mt-50">
          <div className="hide-in-mobile">
            {" "}
            <IconCardList IconCardListData={IconCardListData} />
          </div>
          <MobileMovieSlider movieSlider={mobMovieSlider1} slideid="slide2" />
        </div>
      </div>
      <div className="mt-70">
        <MiniImageBanner1
          id="one"
          MiniImageBanner1Data={MiniImageBanner1Data}
        />
      </div>
      <div className="mt-50"></div>
      <MediumviewWithHover MediumviewData={MediumviewData} />
      {megaviewData.data && megaviewData.data.length > 0 && (
        <div className="mt-70 body-container">
          <Megaview MegaviewData={megaviewData.data} />
        </div>
      )}
      {articleData && articleData.length > 0 && (
        <div className="mt-70">
          <div className="body-container ttc">
            <span className="title-header">
              <span className="red-line3letter">Rec</span>ommended Reading
            </span>
          </div>
          <div className="mt-50">
            <Miniview
              miniviewData={articleData}
              miniviewData2={miniviewDataArticle}
              quotedMiniCardData={quotedMiniCardData}
            />
          </div>
        </div>
      )}
      {articleRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setArticlePage(
                articlePage === 1 ? articlePage + 2 : articlePage + 1
              );
              setArticleLimit(3);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="READ MORE"
              tip="READ MORE"
            />
          </div>
        </div>
      )}
      <div className="body-container mt-70 ttc">
        <span className="title-header">
          <span className="red-line3letter">Oth</span>er Services
        </span>
      </div>
      <div className="mt-70">
        <BlurCardList componentId="1" BlurCardListData={BlurCardListData} />
      </div>
      <div className="mt-70"></div>
      <TwoColumnContactInfo
        TwoColumnContactInfoData={TwoColumnContactInfoData}
      />
    </Layout>
  );
};

export default HireRemoteTeam;
